<template>
    <div class="display-container">
        <div class="editor">
            <span style="margin-right: 10px;">Input</span>
            <br>
            <textarea v-model="jsonInput" @input="formatJson" placeholder="Paste your JSON here..."></textarea>
            <div v-if="jsonError" style="color: red;">{{ jsonError }}</div>
        </div>
        <Output :jsonData="jsonOutput" />
    </div>
</template>
<script>
import Output from './JsonOutput.vue';
export default {
    components: {
        Output,
    },
    data() {
        return {
            jsonInput: '',
            jsonOutput: '',
            jsonError: ''
        }
    },
    methods: {
        formatJson() {
            try {
                const parsedJson = JSON.parse(this.jsonInput);
                this.jsonOutput = JSON.stringify(parsedJson, null, 4);
                this.jsonError = '';
            } catch (e) {
                this.jsonError = 'Invalid JSON: ' + e.message;
                this.jsonOutput = '';
            }
        }
    }
}

</script>

<style scoped>
.display-container {
    display: flex;
    flex-direction: row;
    /* gap: 20px; */
    /* 调整间距 */
    width: 100%;
    height: calc(100vh - 21vh);
    box-sizing: border-box;
    align-items: stretch;
}

.editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-family: monospace, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.editor textarea {
    width: 100%;
    height: 90%;
    /* min-height: 400px; */
    /* 增加最小高度 */
    resize: vertical;
    /* 允许垂直调整大小 */
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.json-viewer {
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 400px;
    /* 增加最小高度 */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
</style>