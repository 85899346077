<template>
    <div class="postman-tool">
        <h1>Postman Web Tool</h1>

        <!-- URL 输入 -->
        <div class="input-group">
            <label for="url">Request URL:</label>
            <input type="text" id="url" v-model="requestUrl" placeholder="Enter URL" />
        </div>

        <!-- 请求方法选择 -->
        <div class="input-group">
            <label for="method">Request Method:</label>
            <select id="method" v-model="requestMethod">
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
            </select>
        </div>

        <!-- Headers 输入 -->
        <div class="input-group">
            <label for="headers">Headers:</label>
            <textarea id="headers" v-model="requestHeaders" placeholder="Enter headers in JSON format"></textarea>
        </div>

        <!-- Body 输入 -->
        <div class="input-group" v-if="requestMethod !== 'GET'">
            <label for="body">Body:</label>
            <textarea id="body" v-model="requestBody" placeholder="Enter body in JSON format"></textarea>
        </div>

        <!-- 发送请求按钮 -->
        <button @click="sendRequest">Send Request</button>

        <!-- 响应结果展示 -->
        <div class="response" v-if="response">
            <h2>Response:</h2>
            <p><strong>Status:</strong> {{ response.status }}</p>
            <p><strong>Headers:</strong></p>
            <pre>{{ response.headers }}</pre>
            <p><strong>Data:</strong></p>
            <pre>{{ response.data }}</pre>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            requestUrl: '',
            requestMethod: 'GET',
            requestHeaders: '',
            requestBody: '',
            response: null,
        };
    },
    methods: {
        async sendRequest() {
            try {
                const headers = this.requestHeaders ? JSON.parse(this.requestHeaders) : {};
                const body = this.requestBody ? JSON.parse(this.requestBody) : null;

                const config = {
                    method: this.requestMethod,
                    url: this.requestUrl,
                    headers: headers,
                    data: body,
                };

                const res = await axios(config);
                this.response = {
                    status: res.status,
                    headers: res.headers,
                    data: res.data,
                };
            } catch (error) {
                this.response = {
                    status: error.response ? error.response.status : 'Error',
                    headers: error.response ? error.response.headers : {},
                    data: error.response ? error.response.data : error.message,
                };
            }
        },
    },
};
</script>

<style scoped>
.postman-tool {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.input-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input,
select,
textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.response {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
}

pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
}
</style>